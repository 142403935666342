var copy = function (content) {
  if (!content) return '没有要复制的信息！'
  content = typeof content === 'string' ? content : content.toString() // 复制内容，必须字符串，数字需要转换为字符串

  //为了兼容有些浏览器 queryCommandSupported 的判断
  if (!document.queryCommandSupported('copy')) {
    // 不支持
    //error('浏览器不支持');
    return '浏览器不支持'
  }
  let textarea = document.createElement('textarea')
  textarea.style.position = 'absolute'
  textarea.style.left = '-9999px'
  textarea.value = content
  textarea.readOnly = 'readOnly'
  document.body.appendChild(textarea)
  textarea.select() // 选择对象
  textarea.setSelectionRange(0, content.length) //核心
  let result = document.execCommand('copy') // 执行浏览器复制命令
  if (result) {
    // success("复制成功~")
    return '复制成功~'
  } else {
    // error("复制失败，请检查h5中调用该方法的方式，是不是用户点击的方式调用的，如果不是请改为用户点击的方式触发该方法，因为h5中安全性，不能js直接调用！")
    return
  }
  textarea.remove()
}
export default copy
